.card {
  max-width: 1200px;
  width: 90%;
  margin: 3rem auto;
  background-color: rgba(255, 255, 255, 0.84);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 2px 3px 5px;
}

@media screen and (max-width: 414px) {
  .card {
    padding: 2rem 1rem;
  }
}