.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.title h2 {
  text-align: left;
}

.title p {
  max-width: 560px;
  margin-top: 2rem;
}

.accordion {
  max-width: 560px;
  width: 100%;
  margin: 2rem auto;
}

.img_container {
  height: 100%;
  overflow: hidden;
}

.img_container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin-bottom: -3rem;
}

.button {
  text-decoration: none;
  color: black;
  font-size: clamp(0.4rem, 2vw, 1.15rem);
  display: flex;
  align-items: center;
  text-align: center;
  border: 2px solid var(--secondary);
  border-radius: 0 25px 0 0;
  background-color: #fff;
  width: 19%;
  padding: 4px;
}

.animate_btn {
  text-decoration: none;
  color: black;
  font-size: clamp(0.24rem, 2vw, 0.94rem);
  display: flex;
  align-items: center;
  text-align: center;
  border: 2px solid var(--secondary);
  border-radius: 0 25px 0 0;
  background-color: #fff;
  width: 19%;
  padding: 4px;
  animation-duration: 2s;
  animation-name: slide;
  transform: translateY(-80%);
}

@keyframes slide {
  from {
    transform: translate(-100%, -80%);
  }
  to {
    transform: translate(0%, -80%);
  }
}


.img_container a:hover {
  /* color: #fff; */
  color: var(--secondary);
  /* background-color: #eb538d; */
  background-color: #f5e3e9;
  transition: all 0.35s ease-in;
}

.img_card {
  display: block;
}

.animate_card {
  animation-duration: 2s;
  animation-name: grow;
}

@keyframes grow {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

.animate_found_card {
  animation-duration: 2s;
  animation-name: grow2;
}

@keyframes grow2 {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.foundation {
  border-radius: 5px;
  max-width: 800px;
  width: 100%;
  margin: 3rem auto;
  overflow: hidden;
}

.foundation img {
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 645px) {
  .img_container img {
    margin-bottom: -2.2rem;
  }
}

@media screen and (max-width: 484px) {
  .img_container img {
    margin-bottom: -1.5rem;
  }
}

@media screen and (max-width: 414px) {
  .container {
    width: 95%;
    padding: 1rem;
  }
  .title {
    font-size: 0.8rem;
  }
  .accordion h2 {
    font-size: 1.2rem;
  }
  .accordion h3 {
    font-size: 1rem;
  }
}