.order {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.order_desc {
  font-size: 1.2rem;
  font-weight: 200;
  margin-bottom: 1rem;
  text-align: center;
}

.order form {
  max-width: 600px;
  width: 100%;
  margin-top: 20px;
}

.input_element {
  display: flex;
  flex-direction: column;
}

.input_element label {
  font-size: 1.1rem;
}

/* .order_full input, */
.order input {
  height: 40px;
  border: none;
  border: 1px solid black;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
  width: 100%;
}

.order input[type="text"] {
  box-shadow: 0 0 0 50px white inset;
}

/* .order_full input:focus, */
.order input:focus, 
.order textarea:focus {
  outline: none;
  border: 1px solid var(--secondary);
}

/* .order_full input:focus::placeholder, */
.order input:focus::placeholder {
  font-size: 10px;
  transform: translateY(-15px);
  transition: all 0.6s ease-in;
}

.order textarea {
  margin: 10px 0;
  padding: 8px 0;
  font-size: 14px;
  padding-left: 10px;
  border: 1px solid black;
  border-radius: 4px;
  width: 100%;
}

.input_element input[type="checkbox"] {
  width: 1.5rem;
  margin-bottom: -1rem;
  cursor: pointer;
}

.input_element div {
  display: flex;
}

.input_element div p {
  font-size: 0.9rem;
  transform: translate(20px, 22px);
}

.break {
  display: none;
}

.file {
  padding-top: 1rem;
  margin-bottom: -0.5rem;
}

.file input {
  margin-bottom: 0;
  border: none;
}

.file input[type=file]::file-selector-button {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 0.5rem;
  height: 2rem;
}
.file input[type=file]::file-selector-button:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
  transition: all 0.35s ease-in;
}

.order button {
  margin: 2rem auto;
}

.thank {
  text-align: center;
  color: var(--secondary);
  margin-top: 0.5rem;
  font-weight: 250;
}


@media screen and (max-width: 820px) {
  .input_element div:nth-child(3) p {
    transform: translate(20px, 20px);
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .break {
    display: block;
  }
  .input_element div:nth-child(3) p {
    transform: translate(20px, 14px);
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .order input {
    height: 38px;
  }
  .input_element input[type="checkbox"] {
    width: 1rem;
  }
  .input_element div p {
    font-size: 0.8rem;
    transform: translate(10px, 22px);
  }
  .input_element div:nth-child(3) p {
    transform: translate(10px, 14px);
  }
}

@media screen and (max-width: 414px) {
  .order_desc {
    font-size: 1.1rem;
  }
  .input_element:nth-child(2)  input::placeholder {
    font-size: 12px;
  }
  .input_element:nth-child(2)  input:focus::placeholder {
    font-size: 8px;
    transition: all 0.6s ease-in;
  }
  .input_element label {
    font-size: 1rem;
  }
}