.hero_container {
  width: 100%;
  height: 400px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.hero {
  max-width: 1200px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.hero_carousel {
  width: 40%; 
  height: 260px;
}

.hero_carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero_desc {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero_desc h1 {
  color: #fff;
  text-align: center;
  transition: all 0.9s ease-in-out;
  animation: slide-down 0.9s ease-in-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero_desc p {
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.9s ease-in-out;
  animation: slide-up 0.9s ease-in-out forwards;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.summary {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.icon_container {
  height: 100px;
  width: 100px;
  background-color: var(--secondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.summary_column {
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.summary_column p {
  text-align: center;
}

.icon_container svg {
  color: #fff;
  font-size: 2.5rem;
}


@media screen and (max-width: 1200px) {
  .hero_carousel,
  .hero_desc {
    width: 50%;
  } 
  .hero_desc button {
    transform: translateY(20px);
  }
}

@media screen and (max-width: 1090px) {
  .hero_desc p {
    font-size: 0.8rem;
  }
  .hero_desc h1 {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 820px) {
  .hero_container {
    height: 680px;
  }
  .hero {
    flex-direction: column;
    align-items: center;
    gap: 5rem;
  }
  .hero_carousel,
  .hero_desc {
    width: 80%;
  }
  .hero_desc {
    gap: 1rem;
  }
  .hero_desc p {
    font-size: 0.9rem;
  }
  .hero_desc h1 {
    margin-bottom: 0;
    /* margin-top: 2rem; */
  }
  .hero_desc button {
    transform: translateY(0);
  }
}

@media screen and (max-width: 670px) {
  .hero_carousel,
  .hero_desc {
    width: 90%;
  }
  .hero_desc h1 {
    margin: 0;
  }
  .summary {
    flex-direction: column;
    align-items: center;
  }
  .summary_column {
    margin-bottom: 1.5rem;
  }
  .icon_container {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .hero_container {
    height: 720px;
  }
  .hero {
    gap: 1.5rem;
  }
  .hero_desc button {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 414px) {
  .hero {
    width: 96%;
    gap: 2rem;
    margin-top: -2rem;
  }
  .hero_carousel,
  .hero_desc {
    width: 96%;
    gap: 1rem;
  }
  .hero_desc p {
    margin-bottom: 1.5rem;
  }
  .hero_desc h1 {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .hero_container {
    height: 680px;
  }
  .hero_desc {
    margin-top: -2rem;
  }
}

