.gallery {
  background-color: var(--primary);
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33.3%;
  -moz-column-width: 33.3%;
  column-width: 33.3%;
  padding: 16px;  
}

.gallery_item {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: -12px;
}

.gallery_item:hover {
  filter: opacity(0.8);
}

.gallery_item p {
  /* color: #a10843; */
  color: #fff;
  font-size: 1.3rem;
  font-weight: 900;
  transform: translate(1rem, -3rem);
}

.image {
  width: 100%;
}

.model {
  width: 100%;
  height: 100vh - 4rem;
  position: fixed;
  top: 4rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 200;
}

.model img {
  width: auto;
  max-width: 100%;
  /* height: 92vh; */
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.model.open {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model.open img {
  position: fixed;
  margin-top: -3.5rem;
}

.model.open svg {
  position: fixed;
  top: -10px;
  right: 10px;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.0);
  color: #fff;
  cursor: pointer;
}
.model.open svg:hover {
  color: var(--secondary);
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .model.open svg {
    top: 5px;
  }
}

@media screen and (max-width: 420px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}