.price_hero_container {
  width: 100%;
  height: 400px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.price_desc_container {
  max-width: 1200px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.price_desc {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1.5rem;
}

.price_desc h1 {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  transition: all 0.9s ease-in-out;
  animation: slide-down 0.9s ease-in-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.price_desc p {
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.9s ease-in-out;
  animation: slide-up 0.9s ease-in-out forwards;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.box {
  width: 50%;
  object-fit: contain;
  object-position: center;
}

.box img {
  width: 85%;
}

.price {
  max-width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 2rem auto;
}

.cards_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.card_container {
  border-radius: 5px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
  object-fit: contain;
  object-position: center;
}

.card_container img {
  width: 100%;
}

.found_container {
  margin-top: 2rem;
  border-radius: 5px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
  object-fit: contain;
  object-position: center;
}

.found_container img {
  width: 100%;
}

.img_card {
  display: block;
}

.animate_card_right {
  animation-duration: 2s;
  animation-name: slide-from-right;
}

@keyframes slide-from-right {
  from {
    transform: translateX(-4rem);
  }
  to {
    transform: translateX(0);
  }
}
.animate_card_left {
  animation-duration: 2s;
  animation-name: slide-from-left;
}

@keyframes slide-from-left {
  from {
    transform: translateX(4rem);
  }
  to {
    transform: translateX(0);
  }
}
.animate_card_grow {
  animation-duration: 2s;
  animation-name: grow;
}

@keyframes grow {
  from {
    /* opacity: 0.5;
    transform: scale(0.7); */
    transform: translateY(-4rem);
  }
  to {
    /* opacity: 1;
    transform: scale(1); */
    transform: translateY(0);
  }
}

.info {
  max-width: 800px;
  margin: auto;
}

.info p {
  font-size: 0.8rem;
}

.info p:first-child {
  font-size: 0.9rem;
}

.info p:first-child,
.info p:nth-child(2) {
  margin-bottom: 0.5rem; 
}

@media screen and (max-width: 1014px) {
  .box img {
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .price_hero_container {
    height: 320px;
  }
  .box img {
    display: none;
  }
  .price_desc_container {
    display: block;
  }
  .price_desc {
    width: 80%;
    margin-left: 10%;
    gap: 0;
  }
  .price_desc h1 {
    display: none;
  }
  .price_desc p {
    margin-bottom: 1rem;
  }
  .price_desc button {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .price_hero_container {
    height: 360px;
  }
  .cards_container {
    grid-template-columns: 1fr;
  }
  .card_container {
    width: 75%;
    margin: 0 auto;
  }
  .found_container:hover {
    transform: scale(1.35);
    transition: transform 1s ease;
  }
  .info p {
    font-size: 0.7rem;
  }
  
  .info p:first-child {
    font-size: 0.8rem;
  }
}

/* @media screen and (max-width: 414px) {
  
} */