.sidebar {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  /* background-color: rgba(6, 2, 29, 0.96); */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100%);
  transition: transform 0.6s ease-out;
}

.sidebar ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sidebar a {
  text-decoration: none;
  font-size: 20px;
  color: #fff;
}

.sidebar a:hover {
  color: var(--secondary);
  transition: all 0.35s ease-in;
}

.sidebar li:nth-child(5) a {
  background-color: var(--secondary);
  padding: 3px 7px;
  border-radius: 5px;
  border: 2px solid var(--secondary);
}

.sidebar li:nth-child(5) a:hover {
  background-color: #fff;
  color: var(--secondary);
}

.sidebar.open {
  transform: translateX(0%);
}

.social_list {
  display: flex;
  gap: 1.5rem;
}

@media screen and (max-width: 480px) {
  .sidebar {
    width: 75%;
  }
}

@media screen and (max-width: 390px) {
  .sidebar {
    width: 100%;
  }
}