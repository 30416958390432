.btn_container {
  display: flex;
  justify-content: center;
}

.btn_container button {
  width: 160px;
  height: 40px;
  background-color: #fff;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 1rem;
  transition: animation 3s ease-in-out;
  animation: appear 3s ease-in-out forwards;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn_container button:hover {
  cursor: pointer;
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
  transition: all 0.35s ease-in;
}

