.nav {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  background-color: var(--primary);
}

.nav_wrap {
  max-width: 1200px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.nav_list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav_list a {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.nav_list a:hover,
.nav_list a:active,
.nav_list a.active {
  color: var(--secondary);
  transition: all 0.35s ease-in;
}

.nav_list li:last-child a:hover,
.nav_list li:last-child a:active,
.nav_list li:last-child a.active {
  color: #fff;
}

.nav_list li:last-child a {
  background-color: var(--secondary);
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid var(--secondary);
}

.nav_list li:last-child a:hover {
  background-color: #fff;
  color: var(--secondary);
}

.social_list {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social_list a:visited,
.social_list a:active,
.social_list a.active,
.social_list a:link,
.social_list a:focus {
  text-decoration: none;
  color: #fff;
}

.social_list a:hover {
  color: var(--secondary);
  transition: all 0.35s ease-in;
  transform: rotate(360deg);
}

@media screen and (max-width: 991px) {
  .nav_list a {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 899px) {
  .nav_list {
    gap: 1rem;
  }
}