.mobile_nav_container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
  height: 4rem;
  padding: 0 2rem;
}

.hamburger_wrap {
  position: fixed;
  top: 0;
  right: 1rem;
  /* z-index: 50; */
}

.toggler {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 51;
  cursor: pointer;
  width: 40px;
  height: 40px;
  opacity: 0;
}

.hamburger {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 50;
  width: 60px;
  height: 60px;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Hamburger Line */
.hamburger > div {
  position: relative;
  flex: none;
  width: 2rem;
  height: 3px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}
/* Hamburger Top & Bottom Lines */
.hamburger > div:before,
.hamburger > div:after {
  content: '';
  position: absolute;
  z-index: 50;
  top: -10px;
  width: 100%;
  height: 3px;
  background: inherit;
}
/* Moves 3rd Line Down */
.hamburger > div:after {
  top: 10px;
}
/* Toggler Animation */
.toggler:checked + .hamburger > div {
  transform: rotate(135deg);
}
/* Turns Lines Into X */
.toggler:checked + .hamburger > div:before,
.toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}
/* Rotate On Hover When Checked */
.toggler:checked:hover + .hamburger > div {
  transform: rotate(225deg);
}

@media screen and (max-width: 414px) {
  .mobile_nav_container {
    padding: 0 0 0 1rem;
  }
  .hamburger_wrap {
    right: 0.2rem;
  }
}